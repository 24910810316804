import React from "react";
import "./leisureMaincontent.css";
import {
  Parallax,
  ParallaxProvider,
  ParallaxBanner,
  ParallaxBannerLayer,
} from "react-scroll-parallax";

const LeisureMaincontent = () => {
  return (
    <ParallaxProvider>
      <div className="responsive-leisure">
        <br />
        <div className="responsive-leisure-pc">
          <ParallaxBanner style={{ aspectRatio: "2 / 1", width: "100%" }}>
            <ParallaxBannerLayer
              image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/le-mid-banner.webp"
              speed={7}
            />
          </ParallaxBanner>
        </div>
        <div className="responsive-leisure-mobile">
          <ParallaxBanner style={{ aspectRatio: "auto 360 / 540" }}>
            <ParallaxBannerLayer
              image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/le-mid-banner.webp"
              speed={10}
            />
          </ParallaxBanner>
        </div>

        <div className="first-para-leisure">
          <p className="para">
            Lalan Leisure is the hospitality and holiday property management
            sector of Lalan Group. A relatively new diversification by the
            Group, Lalan Leisure manages a network of boutique plantation
            bungalows and wildlife chalets in some of the most picturesque
            locations in Sri Lanka. Our strength lies in our passion to provide
            world-class service and ensure our customers have a magical
            experience, be it their first visit or a return visit. We pride
            ourselves in our burgeoning reputation as a modest yet underrated
            player in the boutique hospitality sector, providing consistently
            world-class service across our entire network of destinations.
            <br />
            <br />
          </p>
        </div>

        <div className="first-para-leisure">
          <p className="address">
            Lalan Leisure <br />
            81A, Temple Road, <br />
            Sri Jayawardenepura Kotte, <br />
            Sri Lanka. <br />
            info@lalanleisure.com <br />
            (+ 94) 77 210 5494 <br />
            www.lalanleisure.com <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </p>
        </div>

        <div className="second-para-leisure">
          <div>
            <div className="two-column">
              <div className="column-2">
                {/* Column 1 */}

                <div className="responsive-leisure-pc">
                  <ParallaxBanner
                    style={{ aspectRatio: "2 / 2", width: "100%" }}
                  >
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/a.webp"
                      speed={7}
                    />
                  </ParallaxBanner>
                </div>
                <div className="responsive-leisure-mobile">
                  <ParallaxBanner
                    style={{ aspectRatio: "1 / 1.5", width: "100%" }}
                  >
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/a.webp"
                      speed={10}
                    />
                  </ParallaxBanner>
                </div>
                <br />
                <a
                  href="https://lalanleisure.com/destinations/udapola-bungalow/"
                  className="topic"
                >
                  Udapola Bungalow
                </a>
                <p className="para">
                  Located in Deraniyagala, approximately 2 hours from Colombo,
                  Udapola Bungalow is a five-bedroom planters' bungalow,
                  situated in the heart of the lush Udapola rubber estate. Built
                  in the 1930s with timeless neo-colonial styling, the bungalow
                  has a sprawling garden leading up to a natural-water pool. The
                  large, airy rooms and high ceilings retain its old-world
                  charm, and comfortably accommodates up to 13 people. With
                  plenty of walking trails winding through fruit tree gardens
                  and rubber estate land, Udapola Bungalow is the ideal escape
                  for a quick getaway from the hustle and bustle of city life.{" "}
                  <br />
                  <br />
                </p>
              </div>

              <div className="column-2">
                {/* Column 2 */}
                <div style={{ marginTop: "200px" }}></div>
                <Parallax speed={-20}>
                  <div className="responsive-leisure-pc">
                    <ParallaxBanner
                      style={{ aspectRatio: "2 / 2", width: "100%" }}
                    >
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/b.webp"
                        speed={7}
                      />
                    </ParallaxBanner>
                  </div>
                  <div className="responsive-leisure-mobile">
                    <ParallaxBanner
                      style={{ aspectRatio: "1 / 1.5", width: "100%" }}
                    >
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/b.webp"
                        speed={10}
                      />
                    </ParallaxBanner>
                  </div>
                  <br />
                  <a
                    href="https://lalanleisure.com/destinations/iona-bungalow/"
                    className="topic"
                  >
                    Kudaoya Bungalow
                  </a>
                  <p className="para">
                    Built mid-way up a hill, overlooking the Gammaduwa valley,
                    Kudaoya Bungalow is a planters' bungalow just 1 hour outside
                    Matale. Unique among most planters' bungalows of this era,
                    the defining feature of Kudaoya is the large veranda that
                    spans the entire eastern face of the bungalow, offering a
                    breath-taking view of the sunrise and the valley below.
                    Being at the foothills of the Knuckles Mountain Range, the
                    area surrounding the bungalow is an ecological hotspot for
                    flora and fauna endemic to Sri Lanka's vibrant ecosystem.
                    Five large rooms - all facing the spectacular vista of the
                    Gammaduwa valley - can easily accommodate 20 people. The
                    spice estate below, and the nature trails leading to the
                    many little waterfalls and jungle surrounding the bungalow,
                    means that adventure is always only a short walk away.{" "}
                    <br />
                    <br />
                  </p>
                </Parallax>
              </div>
            </div>
          </div>

          {/* ............................................................................................... */}

          <div style={{ marginTop: "150px" }}>
            <div className="two-column">
              <div className="column-2">
                {/* Column 1 */}

                <div style={{ marginTop: "140px" }}>
                  <div className="responsive-leisure-pc">
                    <ParallaxBanner style={{ aspectRatio: "2 / 1.4" }}>
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/c.webp"
                        speed={7}
                      />
                    </ParallaxBanner>
                  </div>
                  <div className="responsive-leisure-mobile">
                    <ParallaxBanner
                      style={{ aspectRatio: "1 / 1.5", width: "100%" }}
                    >
                      <ParallaxBannerLayer
                        image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/c.webp"
                        speed={10}
                      />
                    </ParallaxBanner>
                  </div>
                </div>
                <br />
                <a
                  href="https://lalanleisure.com/destinations/iona-bungalow/"
                  className="topic"
                >
                  Iona Bungalow
                </a>
                <p className="para">
                  The quintessential, tea-estate planters' bungalow, Iona
                  Bungalow is situated just beyond the small town of
                  Agarapathana, roughly 1 hour from Hatton. Sitting in the
                  middle of the picturesque Iona Estate, with its beautiful
                  colonial architecture, the bungalow has five rooms that can
                  host up to 16 people very comfortably. With cool temperatures
                  year-round, a large garden at the front offers views of the
                  tea estates in the valley below. Not content curled up in the
                  cosy living room with a book? The surrounding tea estates
                  offer trails leading to picture-postcard views, including many
                  small streams. You might even run into our estate manager, who
                  can arrange a tea factory visit, or even a curated tea tasting
                  experience right in the comfort of the bungalow. <br />
                  <br />
                </p>
              </div>

              <div className="column-2">
                {/* Column 2 */}
                <br />
                <br />
                <div className="responsive-leisure-pc">
                  <ParallaxBanner style={{ aspectRatio: "2 / 2" }}>
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/d.webp"
                      speed={7}
                    />
                  </ParallaxBanner>
                </div>
                <div className="responsive-leisure-mobile">
                  <ParallaxBanner
                    style={{ aspectRatio: "1 / 1.5", width: "100%" }}
                  >
                    <ParallaxBannerLayer
                      image="https://lalangroup-2024.sgp1.cdn.digitaloceanspaces.com/sectors-top-lables/les/d.webp"
                      speed={10}
                    />
                  </ParallaxBanner>
                </div>
                <br />
                <a
                  href="https://lalanleisure.com/destinations/yala-chalets/"
                  className="topic"
                >
                  Yala Chalets
                </a>
                <p className="para">
                  The newest addition to the group, Yala Chalets is every
                  wildlife enthusiast's dream - located just 10 minutes from the
                  entrance to the Yala National Park, the property consists of 5
                  fully air conditioned chalets that can accommodate 20 people.
                  With a pool, large garden, and a club house with other
                  recreational activities, there is no shortage of things to do.
                  True to our mantra of world-class service, we can arrange for
                  safaris within Yala National Park with trusted tour operators
                  and qualified guides. <br />
                  <br />
                </p>
              </div>
            </div>
          </div>

          <div className="responsive-leisure-pc">
            <center>
              <h1 className="bigHugeTitle">
                9 Sectors <br />
                of Integrated <br />
                <span
                  style={{
                    color: "#46708a",
                    fontStyle: "italic",
                    fontWeight: "bold",
                  }}
                >
                  Expertise
                </span>
              </h1>
              <div style={{ marginTop: "100px", marginBottom: "200px" }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    height: "200px",
                  }}
                >
                  <div style={{ width: "2px", background: "#46708a" }}></div>
                </div>
              </div>
            </center>
          </div>

          <div
            className="responsive-leisure-mobile"
            style={{ marginTop: "200px", marginBottom: "300px" }}
          >
            <p className="text-max-leisure">
              9 Sectors of Integrated
              <span
                style={{
                  color: "#46708a",
                  fontStyle: "italic",
                  fontWeight: "bold",
                }}
              >
                Expertise
              </span>
            </p>
          </div>
        </div>
      </div>
    </ParallaxProvider>
  );
};

export default LeisureMaincontent;
